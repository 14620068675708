<template>
    <TopNav /> <!-- 新增頂部導航 -->

    <div class="airbit-page">
        <!-- 中央置中的標題 -->
        <div class="title-section">
            <h1>Air:bit 無人機套件: 開啟未來科技的無人機套件</h1>
        </div>

        <!-- 第一個圖片和描述區域 -->
        <div class="main-section">
            <img src="@/assets/airbitpage.png" alt="Air:bit" class="airbit-image" />
            <div class="description">
                <p>
                    Air:bit 是款以 micro:bit 為核心的無人機套件，使用者可以將各種零件組合成四軸無人飛行機，並透過編程、App 或另一隻 micro:bit 以 radio 來控制它的飛行。
                </p>
                <p>
                    Air:bit 套件包含 micro:bit 以外的所需零件，使用者需要自行組裝，在動手製作的過程中，更可以學習電路接駁，培養 maker/STEM 精神。
                </p>
            </div>
        </div>

        <!-- 第二個圖片和描述區域 -->
        <div class="main-section">
            <img src="@/assets/airbit_detail.png" alt="Air:bit Detail Image" class="product-detail-image" />
            <div class="product-description">
                <p>Air:bit 套件包含 extension board（配合 micro:bit 使用）、4 組 motor、rechargable lithium-ion battery、螺旋槳、木製無人機框架、micro:bit 透控器主機板等零件。</p>
                <p>使用者需要自行準備 micro:bit（至少需要 1 組 micro:bit，如需運用 micro:bit 作遙控器則要 2 組），建構好無人機後，就能搭配 Android、iOS 的 App 或 micro:bit 透控器操作 Air:bit。你亦可以編寫程式讓無人機自動行駛，這樣便不需要控制器。</p>
            </div>
        </div>

        <!-- 新增嵌入式影片 -->
        <div class="video-section">
            <h2>介紹影片</h2>
            <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/745Z2pirBh0" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<script>
import TopNav from './TopNav.vue';

export default {
    name: "ProductPage",
    components: {
        TopNav // 引入 TopNav 組件
    }
};
</script>

<style scoped>
/* 頁面的主要樣式 */
.airbit-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

/* 標題區域 */
.title-section {
    text-align: center;
    margin-bottom: 30px;
}

.title-section h1 {
    font-size: 28px; /* 放大標題字體 */
    color: #333;
    font-weight: bold;
}

/* 主要內容區域，圖片和文字左右佈局 */
.main-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px; /* 分隔每個圖片和文字區域 */
    width: 80%; /* 控制寬度 */
    max-width: 1000px;
    gap: 20px; /* 圖片和文字之間的間距 */
}

/* 左側圖片的樣式 */
.airbit-image,
.product-detail-image {
    width: 300px;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 右側文字區域的樣式 */
.description,
.product-description {
    max-width: 500px;
    text-align: left;
    line-height: 1.6;
}

/* 影片區塊的樣式 */
.video-section {
    text-align: center;
    margin-top: 20px;
}

.video-thumbnail {
    width: 560px;
    height: 315px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.video-thumbnail:hover {
    transform: scale(1.05);
}
</style>
