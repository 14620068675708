<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 添加全局樣式（如果有需要） */
</style>