<template>
    <section class="wonderkit-section">
      <div class="wonderkit-content">
        <img src="@/assets/wonderkit.png" alt="Wonder Kit" class="wonderkit-image" />
        <div class="wonderkit-text">
          <h2>WonderKit</h2>
          <h3>讓你的創意動起來！</h3>
          <p>想像 micro:bit 是一個小小的「大腦」，而 WonderKit 就是它的「心臟」，Wonderkit可以提供更強大的動力，將訊號傳送到馬達、燈光等裝置，讓你的創作動起來。</p>
          <p>用 WonderKit 你可以親手打造和編程無人機、氣墊船、小汽車甚至泡泡機等有趣的玩具。我們提供的工具和資源，幫助學習 STE(A)M 教育中的科技和創造。</p>
          <p>發揮你的創意，設計、建造並讓你的作品活起來！</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "WonderKitDisplay"
  };
  </script>
  
  <style scoped>
.wonderkit-section {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.wonderkit-content {
  display: flex;
  align-items: center;
  max-width: 800px;
  gap: 30px;
}

.wonderkit-image {
  width: 400px;
  height: 300px; /* 設置為正方形比例 */
  object-fit: cover; /* 確保圖片填滿容器並保持比例 */
  border-radius: 8px; /* 如果需要圓角 */
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 加陰影效果 */
  margin-top: 60px;
}

.wonderkit-text {
  max-width: 400px;
}

.wonderkit-text h2 {
  font-size: 28px;
  color: #333;
}

.wonderkit-text h3 {
  font-size: 24px;
  color: #f39c12;
}

.wonderkit-text p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin: 10px 0;
}
  </style>
  