<template>
  <div class="dropdown">
    <button @click="$emit('toggle')" :class="{ active: isActive }" class="dropdown-button">
      {{ title }}
    </button>
    <ul v-if="isActive" class="dropdown-menu">
      <li v-for="item in items" :key="item.text" class="dropdown-item">
        <router-link v-if="item.link.startsWith('/')" :to="item.link" class="dropdown-link">
          {{ item.text }}
        </router-link>
        <a v-else :href="item.link" class="dropdown-link">{{ item.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    isActive: Boolean
  }
};
</script>

<style scoped>
/* 下拉選單容器 */
.dropdown {
  position: relative;
  display: inline-block;
}

/* 按鈕樣式 */
.dropdown-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #004080;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.dropdown-button.active, .dropdown-button:hover {
  background-color: #0066cc;
  transform: scale(1.05);
}

/* 下拉選單樣式 */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 10px 0;
  margin-top: 8px;
}

/* 下拉選單項目 */
.dropdown-item {
  padding: 0;
}

/* 選單連結樣式 */
.dropdown-link {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.2s ease, color 0.2s;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
  color: #004080;
}


/* 將下拉選單的邊緣圓角 */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
