<template>
    <div class="homepage">
      <TopNav /> <!-- 新增頂部導航 -->
      <div class="content">
        <HeaderComponent />
        <IntroSection />
        <ProductDisplay />
        <WonderKitDisplay />
        <!--<MicroInfoDisplay />-->
        <SustainabilityDisplay />
        <GettingStartedDisplay />
        <SectionDisplay />
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from './TopNav.vue';
  import HeaderComponent from './Header.vue';
  import IntroSection from './Intro.vue';
  import ProductDisplay from './ProductDisplay.vue';
  import WonderKitDisplay from './WonderKitDisplay.vue';
  /*import MicroInfoDisplay from './MicroInfoDisplay.vue';*/
  import SustainabilityDisplay from './SustainabilityDisplay.vue';
  import GettingStartedDisplay from './GettingStartedDisplay.vue';

  export default {
    components: {
      TopNav, // 引入 TopNav
      HeaderComponent,
      IntroSection,
      ProductDisplay,
      WonderKitDisplay,
      /*MicroInfoDisplay,*/
      SustainabilityDisplay,
      GettingStartedDisplay
    }
  };
  </script>
  