<template>
    <TopNav /> <!-- 新增頂部導航 -->

    <div class="bubblebit-page">
        <!-- 中央置中的標題 -->
        <div class="title-section">
            <h1>Bubble:bit 泡泡套件:讓泡泡機器人成為孩子的創意啟蒙</h1>
        </div>

        <!-- 第一個圖片和描述區域 -->
        <div class="main-section">
            <img src="@/assets/bubblepage.png" alt="Bubble:bit" class="bubblebit-image" />
            <div class="description">
                <p>Bubble</p>
                <p>實用且有趣的學習自動化和未來科技的方式。</p>
                <p>您可以組裝、寫程式，讓這個小機器人會為您呈現燈光效果、揮動機械臂並吹出肥皂泡泡！</p>
                <p>適合四年級以上或 9 歲以上的孩子使用。</p>
            </div>
        </div>

        <!-- 新增嵌入式影片 -->
        <div class="video-section">
            <h2>介紹影片</h2>
            <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/UKVYtKmcMto" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </div>       
    </div>
</template>

<script>
import TopNav from './TopNav.vue';

export default {
    name: "BubblebitPage",
    components: {
        TopNav // 引入 TopNav 組件
    }
};
</script>

<style scoped>
/* 頁面的主要樣式 */
.bubblebit-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

/* 標題區域 */
.title-section {
    text-align: center;
    margin-bottom: 30px;
}

.title-section h1 {
    font-size: 28px;
    color: #333;
    font-weight: bold;
}

/* 主要內容區域，圖片和文字左右佈局 */
.main-section {
    display: flex;
    align-items: center; /* 垂直置中 */
    justify-content: center; /* 水平置中 */
    margin-bottom: 40px;
    width: 80%;
    max-width: 1000px;
    gap: 20px;
}

/* 左側圖片的樣式 */
.wheelbit-image {
    width: 400px; /* 調整圖片寬度以放大 */
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 右側文字區域的樣式 */
.description {
    max-width: 500px;
    text-align: left;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    justify-content: center; /* 文字置中對齊圖片垂直中心 */
}

/* 影片區域 */
.video-section {
    text-align: center;
    margin-top: 20px;
}

.video-thumbnail {
    width: 560px;
    height: 315px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.video-thumbnail:hover {
    transform: scale(1.05);
}
</style>
