<template>
  <div class="top-bar">
    <div class="logo-section">
      <router-link to="/">
        <img src="@/assets/ninelife_logo.png" alt="Ninelife Logo" class="logo ninelife-logo" />
      </router-link>
      <router-link to="/">
        <img src="@/assets/makekit_logo.png" alt="Makekit Logo" class="logo makekit-logo" />
      </router-link>
    </div>
    <nav class="menu">
      <DropdownMenu 
        title="產品" 
        :items="productItems" 
        :isActive="activeDropdown === '產品'" 
        @toggle="toggleDropdown('產品')" 
      />
      <DropdownMenu 
        title="手冊" 
        :items="docsItems" 
        :isActive="activeDropdown === '手冊'" 
        @toggle="toggleDropdown('手冊')" 
      />
      <DropdownMenu 
        title="購買" 
        :items="buyItems" 
        :isActive="activeDropdown === '購買'" 
        @toggle="toggleDropdown('購買')" 
      />
      <DropdownMenu 
        title="關於我們" 
        :items="aboutItems" 
        :isActive="activeDropdown === '關於我們'" 
        @toggle="toggleDropdown('關於我們')" 
      />
    </nav>
  </div>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue';

export default {
  components: { DropdownMenu },
  data() {
    return {
      activeDropdown: null,
      productItems: [
        { text: 'Air:bit', link: '/airbit' },
        { text: 'Hover:bit', link: '/hoverbit' },
        { text: 'Wheel:bit', link: '/wheelbit' },
        { text: 'Bubble:bit', link: '/bubblebit' }
      ],
      docsItems: [
        { text: 'Air:文件', link: '/airbitdocs' },
        { text: 'Hover:文件', link: '/hoverbitdocs' },
        { text: 'Wheel:文件', link: '/wheelbitdocs' },
        { text: 'Bubble:文件', link: '/bubblebitdocs' }
      ],
      buyItems: [
        { text: '商店', link: '/store' }
      ],
      aboutItems: [
        { text: '聯繫我們', link: '/contact' },
        { text: '關於我們', link: '/about' }
      ]
    };
  },
  methods: {
    toggleDropdown(menu) {
      // 切換選單的展開狀態
      this.activeDropdown = this.activeDropdown === menu ? null : menu;
    },
    closeDropdown() {
      // 關閉所有選單
      this.activeDropdown = null;
    },
    handleClickOutside(event) {
      // 檢查點擊是否發生在導航欄外部
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
      }
    }
  },
  mounted() {
    // 當組件掛載時，監聽全局點擊事件
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // 當組件銷毀時，移除全局點擊事件
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: auto;
  margin-right: 10px;
}

.ninelife-logo {
  width: 120px;
}

.makekit-logo {
  width: 120px;
}

.menu {
  display: flex;
  gap: 20px;
}

.menu-item,
.button-style {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #003377;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.menu-item:hover,
.button-style:hover {
  background-color: #002244;
}

/* 下拉選單的樣式（如果有需要） */
.menu-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}
</style>
