<template>
    <TopNav /> <!-- 新增頂部導航 -->
    <div class="store-page">
      <div class="store-image">
        <img src="@/assets/online-store.png" alt="Store Coming Soon" />
      </div>
      <div class="store-text">
        <h2>線上商店即將開幕</h2>
        <p>敬請期待</p>
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from './TopNav.vue'; // 確保路徑正確
  
  export default {
    name: "StorePage",
    components: {
      TopNav, // 註冊元件
    },
  };
  </script>
  
  <style scoped>
  .store-page {
    text-align: center;
    margin: 50px auto;
  }
  
  .store-image img {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .store-text h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .store-text p {
    font-size: 1.2em;
    color: #555;
  }
  </style>
  