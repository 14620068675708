<template>
  <section class="intro">
    <div class="intro-content">
      <img src="@/assets/child.png" alt="Child working with toy" class="intro-image" />
      <div class="intro-text">
        <h2>製造 - 遊戲 - 學習</h2>
        <p>我們引進歐洲知名品牌MakeKit的micro:bit套件</p>
        <p>結合動手做與遊玩，讓孩子在探索中學會程式設計</p>
        <p>為台灣程式教育帶來全新啟發與可能性！</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "IntroSection"
};
</script>

<style scoped>
.intro {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  display: flex; /* 父容器設為 flex 布局 */
  justify-content: center; /* 水平置中 */
  align-items: center; /* 垂直置中 */
}

.intro-content {
  display: flex;
  align-items: center;
  gap: 30px; /* 增加間距 */
  max-width: 1200px; /* 限制內容區域寬度 */
  width: 100%; /* 確保內容區域自適應 */
}

.intro-image {
  width: 400px; /* 增加圖片寬度 */
  border-radius: 8px;
}

.intro-text {
  max-width: 700px;
  text-align: center; /* 讓文字置中對齊 */
}

.intro-text h2 {
  color: #333;
  font-size: 28px; /* 增加標題字體大小 */
  margin-bottom: 15px;
}

.intro-text p {
  margin: 10px 0; /* 增加段落間距 */
  line-height: 1.8; /* 調整行距 */
  color: #666;
  font-size: 18px; /* 增加正文字體大小 */
}
</style>
