<template>
    <TopNav /> <!-- 新增頂部導航 -->

    <div class="hoverbit-page">
        <!-- 中央置中的標題 -->
        <div class="title-section">
            <h1>Hover:bit 飛船套件: 解鎖STEAM學習新玩法</h1>
        </div>

        <!-- 第一個圖片和描述區域 -->
        <div class="main-section">
            <img src="@/assets/hoverbitpage.png" alt="Hover:bit" class="hoverbit-image" />
            <div class="description">
                <p>
                    一款結合實作技能、科學和程式的 STEAM 教育套件。
                </p>
                <p>
                    使用強大的無人機馬達與充氣氣墊來消除摩擦力，。 飛船最高速度可達每小時 30 公里!
                </p>
            </div>
        </div>

        <!-- 第二個圖片和描述區域 -->
        <div class="main-section">
            <img src="@/assets/hoverbitpage2.png" alt="Hover:bit Detail Image" class="product-detail-image" />
            <div class="product-description">
                <p>Hover</p>
                <p>課堂套件內含模板，讓學生可以使用紙板和其他材料設計並製作自己的遙控車輛。</p>
                <p>V2 採用內建的陀螺儀作為防旋轉功能，提供更平穩的漂移體驗。</p>
            </div>
        </div>

        <!-- 新增嵌入式影片 -->
        <div class="video-section">
            <h2>介紹影片</h2>
            <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/0YS2lImtZ4o" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<script>
import TopNav from './TopNav.vue';

export default {
    name: "HoverbitPage",
    components: {
        TopNav // 引入 TopNav 組件
    }
};
</script>

<style scoped>
/* 頁面的主要樣式 */
.hoverbit-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

/* 標題區域 */
.title-section {
    text-align: center;
    margin-bottom: 30px;
}

.title-section h1 {
    font-size: 28px; /* 放大標題字體 */
    color: #333;
    font-weight: bold;
}

/* 主要內容區域，圖片和文字左右佈局 */
.main-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px; /* 分隔每個圖片和文字區域 */
    width: 80%; /* 控制寬度 */
    max-width: 1000px;
    gap: 20px; /* 圖片和文字之間的間距 */
}

/* 左側圖片的樣式 */
.hoverbit-image,
.product-detail-image {
    width: 300px;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 右側文字區域的樣式 */
.description,
.product-description {
    max-width: 500px;
    text-align: left;
    line-height: 1.6;
}

/* 影片區塊的樣式 */
.video-section {
    text-align: center;
    margin-top: 20px;
}

.video-thumbnail {
    width: 560px;
    height: 315px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.video-thumbnail:hover {
    transform: scale(1.05);
}
</style>