<template>
    <TopNav /> <!-- 新增頂部導航 -->
    <div class="airbitdocs-page">
      <h1>Air:bit 開箱&零件介紹</h1>
  
      <div class="section">
        <img src="@/assets/airbitdocs1.png" alt="Air:bit 包裝" />
        <p class="caption">這是Air:bit 飛機套裝的包裝</p>
      </div>
  
      <div class="section">
        <img src="@/assets/airbitdocs2.png" alt="零件內容" />
        <p class="caption">套件內容：木製底板x4片、電池 x8、螺絲釘套件等，micro:bit 擴充板、電池、支架等。</p>
      </div>
  
      <div class="section">
        <img src="@/assets/airbitdocs3.png" alt="Airbit 指南" />
        <p class="caption">Air:bit 的Step by step 指南，可從makekit 官網獲得詳細的 guide book。</p>
      </div>
  
      <div class="section">
        <p class="caption">這款教具採用英國BBC micro:bit，幫助小孩理解怎麼利用micro:bit 控制飛機，實現自動飛行。</p>
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from './TopNav.vue'; // 確保路徑正確
  
  export default {
    name: "AirbitDocsPage",
    components: {
      TopNav, // 註冊元件
    },
  };
  </script>
  
  <style scoped>
  .airbitdocs-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #1a1f71;
  }
  
  .section {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .section img {
    width: 80%;
    max-width: 400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .caption {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 5px;
    font-size: 1em;
  }
  </style>
  