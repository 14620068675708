<template>
    <section class="sustainability-section">
      <div class="sustainability-content">
        <div class="sustainability-image-wrapper">
          <img src="@/assets/sustainability.png" alt="Sustainability" class="sustainability-image" />
        </div>
        <div class="sustainability-text">
          <h2>永續發展</h2>
          <p>教育應該是關於探索的樂趣，這一理念體現在我們所有的產品和服務中。</p>
          <p>我們致力於尋找更好、更具創新性的方式來製作產品。我們專注於使用環保材料，並不斷努力變得更加可持續發展。</p>
          <p>我們主要使用當地製造的材料，並讓產品具有耐用性、可修復性、可重複使用及可回收的特性。</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "SustainabilityDisplay"
  };
  </script>
  
  <style scoped>
  .sustainability-section {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    background: none; /* 确保没有背景颜色 */
  }
  
  .sustainability-content {
    display: flex;
    align-items: center;
    max-width: 800px;
    gap: 30px;
  }
  
  .sustainability-image-wrapper {
    width: 350px;
    height: 300px;
    overflow: hidden; /* 确保溢出的部分隐藏 */
    border-radius: 50%; /* 确保图片是圆形 */
    display: cover;
    justify-content: center;
    align-items: center;
    background: none; /* 去除背景 */
    border: none; /* 如果有边框也去掉 */
    box-shadow: none; /* 确保没有阴影 */
  }
  
  .sustainability-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: none; /* 确保图片背景是透明的 */
  }
  
  .sustainability-text {
    max-width: 400px;
  }
  
  .sustainability-text h2 {
    font-size: 28px;
    color: #333;
  }
  
  .sustainability-text p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin: 10px 0;
  }
  </style>
  