import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import AirbitPage from '../components/AirbitPage.vue';
import HoverbitPage from '@/components/HoverbitPage.vue';
import WheelbitPage from '@/components/WheelbitPage.vue';
import BubblebitPage from '@/components/BubblebitPage.vue';
import ContactPage from '@/components/ContactPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import BlogPage from '@/components/BlogPage.vue';
import AirbitDocsPage from '@/components/AirbitDocsPage.vue';
import HoverbitDocsPage from '@/components/HoverbitDocsPage.vue';
import WheelDocsPage from '@/components/WheelDocsPage.vue';
import BubblebitDocsPage from '@/components/BubblebitDocsPage.vue';
import StorePage from '@/components/StorePage.vue';


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage // 使用新的 HomePage 組件作為首頁
  },
  {
    path: '/airbit',
    name: 'Airbit',
    component: AirbitPage
  },
  {
    path: '/hoverbit',
    name: 'Hoverbit',
    component: HoverbitPage
  },
  {
    path: '/wheelbit',
    name: 'Wheelbit',
    component: WheelbitPage
  },
  {
    path: '/bubblebit',
    name: 'Bubblebit',
    component: BubblebitPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage
  },
  {
    path: '/airbitdocs',
    name: 'AirbitDocs',
    component: AirbitDocsPage
  },
  {
    path: '/hoverbitdocs',
    name: 'HoverbitDocs',
    component: HoverbitDocsPage
  },
  {
    path: '/wheelbitdocs',
    name: 'WheelbitDocs',
    component: WheelDocsPage
  },
  {
    path: '/bubblebitdocs',
    name: 'Bubblebitdocs',
    component: BubblebitDocsPage
  },
  {
    path: '/store',
    name: 'store',
    component: StorePage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
