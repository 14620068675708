<template>
    <TopNav /> <!-- 新增頂部導航 -->
    <div class="hoverbitdocs-page">
      <h1>Hover:bit 開箱&零件介紹</h1>
  
      <div class="section">
        <img src="@/assets/hoverbitdocs1.png" alt="Hover:bit 包裝" />
        <p class="caption">這是 Hover:bit 完整版 開箱，注意 ADD-ON 版 有跟 完整版的零件會有出入。</p>
      </div>
  
      <div class="section">
        <img src="@/assets/hoverbitdocs2.png" alt="零件內容" />
        <p class="caption">零件內容: 紙皮、透明密實袋、木製框架 (結構)、扇葉 x4、螺絲套件連橡筋、micro:bit 擴充板、電池、伺服馬達(servo)、馬達 x2</p>
      </div>
  
      <div class="section">
        <img src="@/assets/hoverbitdocs3.png" alt="Hoverbit 指南" />
        <p class="caption">紙盒 跟 透明密實袋(大) 是零件之一，請勿損壞或丟棄！</p>
      </div>
  
      <div class="section">
        <img src="@/assets/hoverbitdocs4.png" alt="Hoverbit 指南" />
        <p class="caption">Hover:bit 內有step by step 的指示，可以到makekit 官網看到完整的 guide book</p>
        <p class="caption">注意本產品不包含BBC micro:bit ，需要另外購買 2個micro:bit (1個負責主機，1個負責遙控)</p>
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from './TopNav.vue'; // 確保路徑正確
  
  export default {
    name: "HoverbitDocsPage",
    components: {
      TopNav, // 註冊元件
    },
  };
  </script>
  
  <style scoped>
  .hoverbitdocs-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #1a1f71;
  }
  
  .section {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .section img {
    width: 80%;
    max-width: 400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .caption {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 5px;
    font-size: 1em;
  }
  </style>
  