<template>
  <div>
    <TopNav /> <!-- 添加頂部導航 -->
    <div class="contact-page">
      <h1>聯絡信箱</h1>
      <p class="contact-email">kevin99102002@gmail</p>

      <!-- 表單 -->
      <form @submit.prevent="submitForm" class="contact-form">
        <div class="form-group">
          <label>您的大名</label>
          <input type="text" v-model="name" required />
        </div>
        <div class="form-group">
          <label>聯繫用Email</label>
          <input type="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label>電話號碼</label>
          <input type="text" v-model="phone" required />
        </div>
        <div class="form-group">
          <label>聯繫內容</label>
          <textarea v-model="message" required></textarea>
        </div>
        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'; // 若您選擇使用 EmailJS 服務
import TopNav from './TopNav.vue';

export default {
  components: {
    TopNav
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: ''
    };
  },
  methods: {
    async submitForm() {
      // 使用 EmailJS 發送電子郵件
      const serviceId = 'service_riu8c49';
      const templateId = 'template_6au6xm8';
      const userId = 'GmbZoJ9GJv-SDc7ve';
      const templateParams = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      };

      try {
        await emailjs.send(serviceId, templateId, templateParams, userId);
        alert('感謝您的聯絡！我們將儘快與您聯繫。');
        // 清空表單
        this.name = '';
        this.email = '';
        this.phone = '';
        this.message = '';
      } catch (error) {
        alert('寄送失敗，請稍後再試。');
        console.error('EmailJS error:', error);
      }
    }
  }
};
</script>

<style scoped>
.contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px; /* 增加頂部間距 */
  text-align: center;
}

.contact-page h1 {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.contact-email {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

textarea {
  resize: vertical;
  height: 100px;
}

.submit-button {
  background-color: #ff6b35;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #e65a28;
}
</style>
