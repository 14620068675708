<template>
    <TopNav /> <!-- 頂部導航 -->
    <div class="about-page">
      <div class="about-content">
        <div class="logos">
          <img src="@/assets/makekit_logo.png" alt="MakeKit Logo" class="logo makekit-logo" />
          <span class="separator">X</span>
          <img src="@/assets/ninelife_logo2.png" alt="NineLife Tech Logo" class="logo ninelife-logo" />
        </div>
  
        <div class="description">
          <p>
            九運科技由一群在遊戲經營與技術開發領域擁有多年經驗的專業人士共同組成。我們希望在愉快遊戲的同時，也能引領客戶進入新的AI世代，協助快速導入組織並提升生產力，成為客戶的有力後盾。
          </p>
          <p>
            我們與來自挪威的MakeKit合作，MakeKit專注於科技教育，致力於透過「創造的樂趣」來推廣創意、科技和程式教育。他們推出了多款創客套件，例如採用micro開發的Air，提供更輕量、易用且友善的硬體體驗。這些創客套件不僅僅是一種教育工具，更是創意與科技的結合，讓使用者可以自由探索並學習新的技能。
          </p>
          <p>
            透過這次合作，我們結合MakeKit的創客教育專業和九運科技的技術優勢，致力於為客戶打造一個科技創意共融的平台，讓大家能夠在遊戲中學習、創造並成長。期待我們的創新合作，為客戶帶來更高價值和樂趣！
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from './TopNav.vue';
  
  export default {
    components: {
      TopNav
    }
  };
  </script>
  
  <style scoped>
    .about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 50px 5vw;
    position: relative;
    overflow: hidden; /* 防止背景溢出 */
    }

    .about-content {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2; /* 確保內容在背景圖形上層 */
    }

    .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-bottom: 20px;
    }

    .logo {
    height: 10vh;
    max-height: 80px;
    }

    .separator {
    font-size: 2em;
    color: #333;
    }

    .description {
    text-align: left;
    line-height: 1.8;
    font-size: 1.4em; /* 放大字體 */
    }

    /* 固定位置的左下角四分之一圓形背景 */
    .about-page::before {
    content: "";
    position: fixed; /* 設為固定位置，確保在左下角 */
    bottom: 0;
    left: 0;
    width: 25vw; /* 調整寬度以覆蓋更大範圍 */
    height: 25vw;
    background-color: rgba(255, 221, 136, 0.4); /* 增加透明度 */
    border-top-right-radius: 100%;
    z-index: 1;
    }

    @media (max-width: 768px) {
    .about-content {
        width: 95%;
    }

    .logo {
        height: 8vh;
    }

    .description {
        font-size: 1.2em;
    }
  }
    </style>
  