<template>
    <TopNav /> <!-- 頂部導航 -->
  
    <div class="blog-page">
      <div class="header-section">
        <h1>Blog</h1>
      </div>
  
      <div class="intro-text">
        <p>在這裡您可以找到有關 micro:bit 的多篇文章、專案和解決方案以及其他 STEAM 學習產品和建置套件。</p>
      </div>
  
      <div class="blog-content">
        <!-- 照片區域 -->
        <div class="blog-card">
          <img src="@/assets/blog.png" alt="Blog Image" class="full-width-image" />
        </div>
      </div>
    </div>
</template>

<script>
import TopNav from './TopNav.vue';

export default {
  name: "BlogPage",
  components: {
    TopNav
  }
};
</script>

<style scoped>
.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5vw;
}

.header-section {
  background-color: #1a1f71;
  color: #ffffff;
  width: 120vw; /* 確保藍色區域填滿畫面 */
  text-align: center;
  padding: 20px 0;
  margin-left: calc(50% - 50vw); /* 對齊頁面 */
}

.header-section h1 {
  font-size: 2em;
  margin: 0;
}

.intro-text {
  margin: 20px 0;
  font-size: 1.5em; /* 放大字體 */
  font-weight: bold; /* 加粗 */
  text-align: center;
}

.blog-content {
  width: 100%; /* 確保圖片區域填滿頁面寬度 */
  display: flex;
  justify-content: center;
}

.blog-card {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.full-width-image {
  width: 100%;
  height: auto; /* 保持圖片比例 */
  object-fit: cover;
}
</style>
