<template>
    <section class="getting-started-section">
      <div class="getting-started-content">
        <!-- 包裝成連結，點擊時會打開指定的 YouTube 網址 -->
        <a href="https://www.youtube.com/@Makekit" target="_blank" class="video-preview-wrapper">
          <img src="@/assets/getting_started.png" alt="Getting Started" class="video-preview" />
          <div class="play-button">▶</div>
        </a>
        <div class="getting-started-text">
          <h2>如何開始玩！</h2>
          <p>我們的 YouTube 頻道包含了您需要了解的產品相關資訊。</p>
          <p>在這裡，您可以找到從指南和操作說明，到協助解決建構和編碼產品時的挑戰，還有許多小技巧和提示。</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "GettingStartedDisplay"
  };
  </script>
  
  <style scoped>
  .getting-started-section {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .getting-started-content {
    display: flex;
    align-items: center;
    max-width: 800px;
    gap: 30px;
  }
  
  .video-preview-wrapper {
  width: 400px; /* 調整寬度，與上方圖片相匹配 */
  height: 300px; /* 調整高度，與上方圖片相匹配 */
  overflow: cover;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); /* 創建菱形效果 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: none; /* 去除背景 */
  border: none; /* 如果有边框也去掉 */
  box-shadow: none; /* 确保没有阴影 */
}

.video-preview {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 確保圖片不變形 */
}

.play-button {
  position: absolute;
  font-size: 50px; /* 調整播放按鈕大小 */
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}
  
  .getting-started-text {
    max-width: 400px;
  }
  
  .getting-started-text h2 {
    font-size: 28px;
    color: #333;
  }
  
  .getting-started-text p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin: 10px 0;
  }
  </style>
  