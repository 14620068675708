<template>
  <div class="product-display-container">
    <div class="product-grid">
      <!-- 使用 v-for 動態生成卡片 -->
      <router-link
        v-for="(product, index) in products"
        :key="index"
        :to="product.link"
        class="product-card"
      >
        <img :src="product.image" :alt="product.title" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDisplay",
  data() {
    return {
      products: [
        { title: "Air:bit", link: "/airbit", image: require("@/assets/airbit.png") },
        { title: "Wheel:bit", link: "/wheelbit", image: require("@/assets/wheelbit.png") },
        { title: "Hover:bit", link: "/hoverbit", image: require("@/assets/hoverbit.png") },
        { title: "Bubble:bit", link: "/bubblebit", image: require("@/assets/bubblebit.png") }
      ]
    };
  }
};
</script>

<style scoped>
/* 父容器，用於控制圖片區域與上方內容之間的距離 */
.product-display-container {
  margin-top: 40px;
  padding: 0 10px; /* 增加左右邊距，防止內容貼邊 */
}

/* 圖片網格布局 */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 調整每列的最小寬度 */
  gap: 100px; /* 卡片之間更大的間距 */
  max-width: 1400px; /* 最大寬度限制 */
  margin: 0 auto; /* 置中 */
}

/* 每張產品卡片的樣式 */
.product-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* 白色背景 */
  border: 2px solid #ddd; /* 加粗邊框 */
  border-radius: 12px; /* 更大的圓角 */
  padding: 20px; /* 增加內部空間 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* 更明顯的陰影 */
  transition: transform 0.3s, box-shadow 0.3s; /* 過渡效果 */
  text-decoration: none; /* 移除 router-link 的下劃線 */
}

/* 鼠標懸停時的效果 */
.product-card:hover {
  transform: scale(1.1); /* 放大效果 */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); /* 增強陰影 */
}

/* 圖片的樣式，統一大小 */
.product-card img {
  max-width: 100%; /* 圖片填充容器寬度 */
  max-height: 180px; /* 控制圖片高度 */
  object-fit: contain; /* 保持圖片比例 */
  border-radius: 8px; /* 圓角效果 */
}

/* 響應式設計：手機適配 */
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* 更小的列寬 */
    gap: 20px; /* 減少間距 */
  }

  .product-card {
    padding: 15px; /* 減少內部空間 */
  }

  .product-card img {
    max-height: 150px; /* 縮小圖片高度 */
  }
}

/* 響應式設計：大螢幕放大 */
@media (min-width: 1400px) {
  .product-card {
    padding: 30px; /* 增加內部空間 */
    transform: scale(1.1); /* 預設放大一點 */
  }

  .product-card img {
    max-height: 250px; /* 更大的圖片高度 */
  }
}

</style>
