<template>
  <header>
    <h1>風靡歐美的Micro:bit套件即將在台上市!</h1>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent"
};
</script>

<style scoped>
header {
  display: flex;
  justify-content: center; /* 將標題置中 */
  align-items: center;
  background-color: #004080;
  color: white;
  padding: 20px; /* 增加一些 padding 讓標題區域看起來更大 */
}

h1 {
  margin: 0; /* 移除默認的 margin，讓標題更集中 */
  font-size: 24px; /* 可以根據需要調整字體大小 */
  font-weight: normal;
}
</style>
